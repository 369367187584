import request from '@http';

// 2022/10/20 发送一键催收信息
export function messageSend(uuid, type) {
    return request({
        method: 'put',
        url: `/api/gzf/leasor/message/send/${uuid}?type=${type}`,
    })
}

// 2022/10/20 消息列表
export function messagePager(params) {
    return request({
        method: 'get',
        url: `/api/gzf/leasor/message/page`,
        params,
    })
}