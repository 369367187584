<template>
     <section class="book">
          <r-e-empty description="暂无相关信息" />
     </section>
</template>

<script>
    export default {
        name: "book"
    };
</script>

<style scoped>

</style>
